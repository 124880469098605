import DynamicZone from '../components/dynamic-zone/dynamic-zone';
import FloatingButton from '../components/floating-button/floating-button';
import Layout from '../components/layout';
import React from 'react';
import { graphql } from 'gatsby';

const NotFoundPage = (props) => {
    const components = props.data.cms.notFoundPageTemplate?.components || null;
    return (
        <Layout location={props.location} locale={props.pageContext.locale}>
            <div id="not-found-page-404">
                <FloatingButton />
                <DynamicZone components={components}/>
            </div>
        </Layout>
    );
};

export default NotFoundPage;

export const query = graphql`
query ($locale: String!){
  cms {
    notFoundPageTemplate(locale: $locale) {
      components {
        ... on STRAPI_ComponentComponentsHeader {
          __typename
          backgroundImage {
            url
          }
          textBoxCustomClasses
          backgroundImageHeight
          backgroundTopOffset
          contentOffset
          mobileContentOffset
          headerSubtitle: subtitle
          title
          textBoxCustomClasses
          subtitleImages {
            icon {
              url
            }
            title
            iconLink
          }
        }
        ... on STRAPI_ComponentSeoSeo {
          __typename
          seoDescription
          seoLink
          seoTitle
          seoImages {
            url
          }
        }
      }
    }
  }
}
`;
